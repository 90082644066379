import React, { useEffect } from "react"
import ContactUs from "../components/ContactUs/ContactUs"

import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
export default function Contact() {
  

  

  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/contact" />
      </Helmet>
      <ContactUs queries />
 
    </Layout>
  )
}
